.swiper-container {
  width: 100vw;
  /* margin: 40px 0; */
  /* padding: 45px 0; */
}

.swiper-slide {
  opacity: 1;
  /* overflow: hidden; */
  transform: scale(1) !important;
  /* transition: transform 0.7s ease, opacity 0.7s ease; */
}

.swiper-slide img {
  width: calc(100vw / 3.1);
  height: 427px !important;
  display: block;
  transform: scale(0.9) translateY(6px) !important;
  margin-left: 7px;
}

.swiper-slide-active {
  opacity: 1;
  z-index: 1;
  transition: transform 1s ease;
  transform: scale(1.12) translateY(-3px) !important;
}

/* Optional: Add a smoother transition effect to the carousel container */
.swiper-wrapper {
  transition: transform 1s ease;
}
