.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.slick-dots li button:before {
  font-size: 9px !important;
}

.slick-dots li {
  margin: 0 !important;
}

.form-control {
  height: 45px !important;
  width: 460px !important;
}
.react-tel-input {
  margin-top: 10px !important;
}

@media (max-width: 870px) {
  .slick-dots {
    bottom: 5px !important;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body::-webkit-scrollbar,
*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border-radius: 50px !important;
}

body::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
}

body::-webkit-scrollbar-track,
*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
}
